import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/docs-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <blockquote>
      <p parentName="blockquote">{`Firstparty.js automatically creates and maintains sessions, which should cover most web Analytics use cases.
If you're using a JavaScript Source and Firstparty.js to collect Events on your website, you don't need to do
anything else to collect and maintain Sessions.`}</p>
    </blockquote>
    <p>{`A Session is a simple Data Object that has a known ID as well as a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`created_at`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`updated_at`}</code>{` timestamps. Sessions make it easier to analyze and group Events sent within a fixed period of time by an individual user.`}</p>
    <h2 {...{
      "id": "creating-a-session",
      "style": {
        "position": "relative"
      }
    }}>{`Creating a Session`}<a parentName="h2" {...{
        "href": "#creating-a-session",
        "aria-label": "creating a session permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Session tracking in Firstparty starts automatically whenever the Event is collected by a JavaScript Source. `}<strong parentName="p">{`Firstparty
will automatically create a new Session`}</strong>{` if there is no `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`session_id`}</code>{` present on the Event object AND if there is no
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`fsid`}</code>{` cookie present in the request sent from firstparty.js, a browser, or any HTTP client.`}</p>
    <p><strong parentName="p">{`Sessions are not automatically expired by Firstparty`}</strong>{`. Firstparty.js will maintain a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`fsid`}</code>{` Session cookie, which
will expire after the browser session ends. This behavior may vary from browser to browser, but typically involves
ending the session when the browser window is closed.`}</p>
    <h2 {...{
      "id": "attaching-events-to-a-session",
      "style": {
        "position": "relative"
      }
    }}>{`Attaching Events to a Session`}<a parentName="h2" {...{
        "href": "#attaching-events-to-a-session",
        "aria-label": "attaching events to a session permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Events will automatically be associated with a Session if, when the Event is collected by Firstparty, one of the
following situations are true:`}</p>
    <ul>
      <li parentName="ul">{`The Event payload has a valid Session ID set on the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`session_id`}</code>{` key`}</li>
      <li parentName="ul">{`The HTTP request sending the Event to Firstparty has a valid Session ID set on the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`fsid`}</code>{` cookie`}</li>
    </ul>
    <h2 {...{
      "id": "tracking-sessions-across-sources",
      "style": {
        "position": "relative"
      }
    }}>{`Tracking Sessions Across Sources`}<a parentName="h2" {...{
        "href": "#tracking-sessions-across-sources",
        "aria-label": "tracking sessions across sources permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`You may wish to associate Events sent from different Sources with the same Session. This is useful when you want to
associate an Event that is generated from a web server via an HTTP Source with an in-progress Session where a user is
generating Events in a browser via Firstparty.js.`}</p>
    <p>{`Send an existing Session ID as the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`session_id`}</code>{` parameter on the Event, and Firstparty will automatically associate
the newly collected Event with the existing Session, even if the Session and Event were created from different
sources.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      